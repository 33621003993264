import * as React from 'react'
import * as ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import mapbox from 'mapbox-gl'
import { useRouter } from 'next/router'
import * as ObjectUtils from '@avcan/utils/object'
import * as Config from 'services/mapbox/config'
import { captureException } from 'services/sentry'
import LOCALE, { LOCALES } from '@avcan/constants/locales'

Map.propTypes = {
    options: PropTypes.object,
    on: PropTypes.object,
    once: PropTypes.object,
    children: PropTypes.node,
}

export function Map({ options = {}, on = ON, once = ONCE, style = Config.STYLES.default, children }) {
    const { locale } = useRouter()
    const containerRef = React.useRef(null)
    const [map, setMap] = React.useState(null)
    const [ready, setReady] = React.useState(false)

    React.useEffect(() => {
        const map = new mapbox.Map({
            style,
            dragRotate: false,
            accessToken: Config.ACCESS_TOKEN,
            ...options,
            container: containerRef.current,
        })

        map.once('load', () => {
            setReady(true)
            // map.setLayoutProperty('country-label', 'text-field', ['get', `name_${locale}`])
        })

        map.on('error', event => {
            captureException(event.error)
        })

        for (const [type, listener] of Object.entries(once)) {
            map.once(type, listener)
        }

        setMap(map)

        return () => {
            map.remove()
        }
    }, [])

    React.useEffect(() => {
        if (!map) {
            return
        }

        const events = Object.entries(on)

        for (const [type, listener] of events) {
            map.on(type, listener)
        }

        return () => {
            for (const [type, listener] of events) {
                map.off(type, listener)
            }
        }
    }, [map, on])

    return (
        <context.Provider value={map}>
            <div ref={containerRef} style={MAP_CONTAINER_STYLE} />
            {React.Children.toArray(children).filter(child => {
                switch (child.type) {
                    case Source:
                    case Layer:
                    case Marker:
                    case Popup:
                    case WithMapReady:
                        return ready
                    case NavigationControl:
                    case FullscreenControl:
                    case GeolocateControl:
                    case WithMap:
                        return Boolean(map)
                    default:
                        return true
                }
            })}
        </context.Provider>
    )
}

export function WithMap({ children }) {
    return children
}
export function WithMapReady({ children }) {
    return children
}

export function Source({ id, data, children, ...source }) {
    const [added, setAdded] = React.useState(false)
    const map = useMap()

    React.useEffect(() => {
        if (added) {
            map.getSource(id).setData(data)
        }
    }, [data])

    React.useEffect(() => {
        const newSource = ObjectUtils.clean({
            type: 'geojson',
            data,
            clusterMaxZoom: 13,
            clusterRadius: 35,
            promoteId: 'id',
            ...source,
        })

        if (!map.getSource(id)) {
            map.addSource(id, newSource)
        }

        setAdded(true)
    }, [])

    return React.Children.map(children, child => {
        if (child.type !== Layer) {
            return child
        }

        if (!added) {
            return null
        }

        return React.cloneElement(child, {
            source: child.props.source || id,
        })
    })
}

VectorTileSource.propTypes = {
    id: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    children: PropTypes.node,
}

export function VectorTileSource({ id, children, ...source }) {
    const [added, setAdded] = React.useState(false)
    const map = useMap()

    React.useEffect(() => {
        Object.assign(source, { type: 'vector' })

        if (!map.getSource(id)) {
            map.addSource(id, source)
        }

        setAdded(true)
    }, [])

    return React.Children.map(children, child => {
        if (child.type !== Layer) {
            return child
        }

        if (!added) {
            return null
        }

        return React.cloneElement(child, {
            source: child.props.source || id,
        })
    })
}

export function Layer({ id, beforeId, visible = true, filter, on = ON, children = null, ...rest }) {
    const { locale } = useRouter()
    const map = useMap()
    const visibility = visible ? 'visible' : 'none'

    React.useEffect(() => {
        // Uses Object.assign because layer.layout could be "undefined"
        const layout = Object.assign({ visibility }, rest.layout)
        const layer = ObjectUtils.clean({ ...rest, filter, layout, id })

        if (!map.getLayer(layer.id)) {
            map.addLayer(layer, beforeId)
        }

        for (const [type, handler] of Object.entries(on)) {
            map.on(type, id, handler)
        }
    }, [])

    React.useEffect(() => {
        map.setLayoutProperty(id, 'visibility', visibility)
    }, [visibility])

    React.useEffect(() => {
        map.setFilter(id, filter)
    }, [filter])

    React.useEffect(() => {
        if ('layout' in rest === false || locale === LOCALE) {
            return
        }

        const [operator, field] = map.getLayoutProperty(id, 'text-field') || []

        if (operator !== 'get' || typeof field !== 'string' || !field.endsWith('_' + LOCALE)) {
            return
        }
    }, [id, locale])

    return children
}

export function Marker({ lnglat, options = {}, on = ON, children }) {
    const map = useMap()
    const ref = React.useRef(document.createElement('div'))
    const marker = React.useRef(null)

    React.useEffect(() => {
        marker.current = new mapbox.Marker({ ...options, element: ref.current })

        marker.current.setLngLat(lnglat).addTo(map)

        return () => {
            marker.current.remove()
        }
    }, [])

    React.useEffect(() => {
        marker.current.setLngLat(lnglat)
    }, [lnglat])

    React.useEffect(() => {
        const events = Object.entries(on)

        for (const [type, listener] of events) {
            marker.current.on(type, listener)
        }

        return () => {
            for (const [type, listener] of events) {
                marker.current.off(type, listener)
            }
        }
    }, [on])

    return ReactDOM.createPortal(children, ref.current)
}

export function NavigationControl({ options, position }) {
    return useControl(mapbox.NavigationControl, Object.assign({ showCompass: false }, options), position)
}

export function FullscreenControl({ options, position }) {
    return useControl(mapbox.FullscreenControl, options, position)
}

export function GeolocateControl({ options, position }) {
    return useControl(mapbox.GeolocateControl, options, position)
}

export function Popup({ children, lnglat, ...options }) {
    const ref = React.useRef(document.createElement('div'))
    const map = useMap()

    React.useEffect(() => {
        const popup = new mapbox.Popup(options)

        popup.setDOMContent(ref.current).setLngLat(lnglat).addTo(map)

        return () => {
            popup.remove()
        }
    }, [lnglat])

    return ReactDOM.createPortal(children, ref.current)
}

export function useMap() {
    return React.useContext(context)
}

export function useLayerCursorEvents() {
    // const intl = useIntl()

    return React.useMemo(() => {
        let counter = 0

        return {
            mouseenter({ target }) {
                const canvas = target.getCanvas()

                canvas.style.cursor = 'pointer'
                counter++
            },
            mouseleave({ target }) {
                const canvas = target.getCanvas()

                counter--

                if (counter < 1) {
                    canvas.style.cursor = ''
                }

                canvas.title = ''
            },
            mousemove({ target, features }) {
                // This is the best way to handle title!
                // mouseenter does not work as well as here
                const canvas = target.getCanvas()
                const [{ properties }] = features
                const { name, title, point_count, cluster } = properties

                canvas.title = cluster ? `${title} cluster of ${point_count}` : name || title ? title : ''
            },
        }
    }, [])
}

// Utils and constants
function useControl(ControlClass, options, position = 'bottom-right') {
    const map = useMap()

    React.useEffect(() => {
        const control = new ControlClass(options)

        map.addControl(control, position)

        return () => {
            if (map.loaded()) {
                map.removeControl(control)
            }
        }
    }, [])

    return null
}
const context = React.createContext(null)
const MAP_CONTAINER_STYLE = {
    height: '100%',
}
const ON = {}
const ONCE = {}
